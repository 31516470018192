var user = localStorage.getItem("user");
user = JSON.parse(user);
var arr = [
  {
    title: 'الرئيسية',
    route: 'home',
    icon: 'HomeIcon',
  },
  {
    title: 'التحكم في الطلاب',
    icon: 'UsersIcon',
    g: 'students',
    tagVariant: 'light-warning',
    children: [
      {
        title: 'استعراض الطلاب',
        route: 'view-students',
      },
      {
        title: 'إضافة طالب',
        route: 'add-students',
      },
      {
        title: 'استيراد الطلاب',
        route: 'import-students',
      },
      {
        title: 'ترحيل الطلاب',
        route: 'move-students',
      },
      {
        title: 'انشاء اولياء الامور (تلقائياً)',
        route: 'auto-parents',
      },
    ],
  },
  {
    title: 'أولياء الأمور',
    icon: 'UsersIcon',
    g: 'parents',
    tagVariant: 'light-warning',
    children: [
      {
        title: 'إضافة ولي أمر',
        route: 'parents-add',
      },
      {
        title: 'استعراض أولياء الأمور',
        route: 'parents-view',
      },
      {
        title: 'اجهزة اولياء الامور',
        route: 'parents-devices',
      },
    ],
  },
  {
    title: 'خطوط السير والباصات',
    icon: 'TruckIcon',
    g: 'two',
    tagVariant: 'light-warning',
    children: [
      {
        title: 'إضافة خط سير',
        route: 'groups-add',
      },
      {
        title: 'استعراض الخطوط',
        route: 'groups-view',
      },
      {
        title: 'إضافة باص',
        route: 'bus-add',
      },
      {
        title: 'استعراض الباصات',
        route: 'bus-view',
      },
      {
        title: 'مشرفين الباصات',
        route: 'bus-admins',
      },
      {
        title: 'ارشيف الرحلات',
        route: 'archive',
      },
    ],
  },
  {
    title: 'مشرفين الموقع',
    icon: 'ShieldIcon',
    g: 'admins',
    tagVariant: 'light-warning',
    children: [
      {
        title: 'إضافة مشرف',
        route: 'admins-add',
      },
      {
        title: 'استعراض المشرفين',
        route: 'admins-view',
      },
    ],
  },
  {
    title: 'إعدادات النظام',
    route: 'settings',
    g: 'settings',
    icon: 'SettingsIcon',
  },
  {
    title: 'الاشعارات السابقة',
    g: 'noti',
    route: 'noti',
    icon: 'BellIcon',
  },
  {
    title: 'السجلات (LOGS)',
    g: 'logs',
    route: 'logs',
    icon: 'FileIcon',
  },
  {
    title: 'الاقسام (الفروع)',
    g: 'settings',
    route: 'sections',
    icon: 'ListIcon'
  },
  {
    title: 'خرائط جوجل',
    href: 'https://maps.google.com/',
    icon: 'MapIcon',
  },
], allowed = []

var t = false;

arr.forEach((r) => {
  if (user._id) {
    if (user.all_rules) {
      t = true
    } else {
      t = false;
      if (r.children) {
        if (r.g) {
          if (user.all_rules) {
            t = true
          } else {
            if (user.rules) {
              if (user.rules[r.g]) {
                if (user.rules[r.g].code) {
                  if (user.rules[r.g].allowed) {
                    t = true;
                    var i = 0
                    r.children.forEach(function (e) {
                      if (e.route.includes('add') && user.rules[r.g].add != 'true') {
                        r.children[i] = undefined
                      }
                      if (e.route.includes('view') && user.rules[r.g].view != 'true') {
                        r.children[i] = undefined
                      }
                      i++
                    })
                  }
                }
              }
            }
            if (!user.all_rules && r.g == 'admins') {
              t = false
            }
          }
          if (r.g == "two") {
            r.children.forEach(function (e) {
              if (user.rules) {
                if (e.route.includes('groups') && user.rules['groups'].allowed == 'true' || e.route.includes('bus') && user.rules['bus'].allowed == 'true') {
                  t = true
                }
              }
            })
          }
        }
      } else {
        if (r.route == 'settings' && user.all_rules || r.route == 'noti' && user.all_rules || r.route == 'logs' && user.all_rules) {
          t = true
        }
        if (!r.g) {
          t = true
        }
      }
    }
    if (t) {
      allowed.push(r)
    }
  }
})

export default allowed
